<template>
  <div id="app">
    <Converter />
  </div>
</template>

<script>
import Converter from './components/Converter'

export default {
  name: 'App',
  components: {
    Converter
  }
}
</script>

<style>
</style>
